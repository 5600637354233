import api from "./api.ts";
import { ApiPayload, ForceOnlyOfficeSaveResponse, PrepareCollectionResponse } from "@common/types";
import { RootState } from "@/redux/store.ts";
import { setChatNotification } from "@/redux/slices";

export const documentsIndexApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    callPrepareCollectionStep: builder.query({
      query: (url) => ({
        url,
        method: "POST",
      }),
      transformResponse: (response: ApiPayload<PrepareCollectionResponse>) => response?.data,
    }),
    getIsCollectionPrepared: builder.query<string | null, string | undefined>({
      query: (collection_id) => ({
        url: "index/v2/is_collection_prepared",
        params: {
          collection_id,
        },
      }),
      transformResponse: (response: ApiPayload<string | null>) => response?.data,
    }),
    prepareCollection: builder.query({
      query: (collection_id) => ({
        url: "index/prepare_collection",
        method: "POST",
        params: {
          collection_id,
        },
      }),
      transformResponse: (response: ApiPayload<PrepareCollectionResponse>) => response?.data,
    }),
    forceOnlyOfficeSave: builder.query({
      query: (collection_id) => ({
        url: "index/force_onlyoffice_save",
        method: "POST",
        params: {
          collection_id,
        },
      }),
      transformResponse: (response: ApiPayload<ForceOnlyOfficeSaveResponse>) => response?.data,
    }),
    checkAndPrepareCollection: builder.query<boolean, string | undefined>({
      async queryFn(collection_id, { getState, dispatch }) {
        try {
          await dispatch(
            documentsIndexApi.endpoints.forceOnlyOfficeSave.initiate(collection_id, {
              forceRefetch: true,
            })
          );

          const pollForPreparationStatus = async (
            collection_id: string | undefined
          ): Promise<boolean> => {
            while (true) {
              const { documents } = <RootState>getState();
              const selectedDocumentCollectionId = documents.selectedDocument?.collection_id;

              // No need to continue polling if the selected document has changed
              if (selectedDocumentCollectionId !== collection_id) {
                return false;
              }

              const { data, isError: isCollectionPreparedError } = await dispatch(
                documentsIndexApi.endpoints.getIsCollectionPrepared.initiate(collection_id, {
                  forceRefetch: true,
                })
              );

              if (isCollectionPreparedError) {
                throw new Error("Error while checking collection preparation status.");
              }

              if (data === "FAILED") {
                throw new Error();
              }

              if (data === "NOT_FOUND") {
                return false;
              }

              if (data === "COMPLETED") {
                return true;
              }

              if (data === "PENDING" || data === "IN_PROGRESS") {
                await new Promise((resolve) => setTimeout(resolve, 2000));
              }
            }
          };

          const isCollectionPrepared = await pollForPreparationStatus(collection_id);

          const { documents } = <RootState>getState();
          const isRedacted = documents.selectedDocument?.is_redacted;

          // Re-fetch the user file tree if the collection was redacted. This is needed because
          // redaction might have happened during the collection pre-processing stage, and the
          // document's `redacted_url` field should be filled in / updated. If we don't do this,
          // then the user will not be able to view the redacted view of the document for
          // newly uploaded documents.
          // This file tree re-fetching might be unnecessary in the future when we implement a
          // separate redaction tab/tool.
          if (isRedacted) {
            dispatch(
              api.util.invalidateTags([{ type: "Documents", id: documents.selectedDocument?.id }])
            );
          }

          return { data: isCollectionPrepared };
        } catch {
          return {
            error: {
              error: "An error occurred while preparing collection.",
              status: "CUSTOM_ERROR",
            },
          };
        } finally {
          if (collection_id) {
            dispatch(setChatNotification({ id: collection_id, message: null }));
          }
        }
      },
    }),
  }),
});

export const { useLazyCheckAndPrepareCollectionQuery } = documentsIndexApi;
